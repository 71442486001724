import styled from "styled-components";

export const StyledHeader = styled.p`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;
  margin-bottom: 13px;

  @media (max-width: 768px) {
    margin-bottom: 18px;
  }
`;
