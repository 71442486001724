import PropTypes from "prop-types";
import React from "react";
import { StyledHeader } from "./styles";

const Title = (props) => {
  return <StyledHeader>{props.value}</StyledHeader>;
};

Title.propTypes = {
  value: PropTypes.string,
};

export default Title;
