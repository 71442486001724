import {
  Container,
  Content,
  ContentBox,
  Media,
  StyledImg,
  TextBlock,
} from "./styles";
import PropTypes from "prop-types";
import React from "react";
import { useImage } from "@queries/use-image";

export const Paragraph = TextBlock;

const Block = (props) => {
  const { getFluidImageName } = useImage();

  return (
    <Container>
      <ContentBox>
        <Content>{props.children}</Content>
      </ContentBox>
      <Media>
        <StyledImg
          fluid={getFluidImageName(props.image)}
          alt="Pessoas em cima da montanha"
        />
      </Media>
    </Container>
  );
};

Block.propTypes = {
  children: PropTypes.node,
  image: PropTypes.string,
};

export default Block;
