import Img from "gatsby-image";
import styled from "styled-components";

export const TextBlock = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #060038;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const Container = styled.section`
  margin-bottom: 60px;
  display: flex;
  flex-direction: row;
  align-items: start;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContentBox = styled.div`
  flex: 1;
`;

export const Content = styled.div`
  & > ${TextBlock} + ${TextBlock} {
    margin-top: 22px;
  }

  @media (max-width: 768px) {
    & > ${TextBlock} + ${TextBlock} {
      margin-top: 22px;
    }
  }
`;

export const Media = styled.div`
  flex: 1;
  max-width: 255px;
  max-height: 200px;
  margin-left: 125px;

  @media (min-width: 769px) and (max-width: 1024px) {
    margin-left: 75px;
  }

  @media (max-width: 768px) {
    max-width: unset;
    margin-left: 0;
    width: 100%;
    margin-top: 60px;
  }
`;

export const StyledImg = styled(Img)`
  height: 100%;
  border-radius: 5px;

  @media (max-width: 768px) {
    height: 200px;
  }
`;
