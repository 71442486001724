/* eslint-disable react/prop-types */
import Block, { Paragraph } from "@components/block/block-component";
import EmptySpace from "@components/empty-space/component";
import FullBleed from "@components/full-bleed";
import Img from "gatsby-image";
import Layout from "@components/layout";
import { Link } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Newsletter from "@components/newsletter/newsletter-component";
import ProjectsCarousel from "@sections/homepage/projects-carousel/projects-carousel-component";
import React from "react";
import Seo from "@components/seo";
import Title from "@components/title/title-component";
import { graphql } from "gatsby";
import styled from "styled-components";
import { useImage } from "@queries/use-image";

const HeroSection = styled.div`
  display: grid;
  grid-template-columns: 1fr calc(min(1110px, calc(100% - 32px))) 1fr;
  grid-template-rows: repeat(3, auto);

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const BG = styled.div`
  background-color: #060038;

  color: white;
  grid-area: 2 / 1 / 4 / 4;

  @media (max-width: 768px) {
    grid-area: 2 / 1 / 4 / 3;
  }
`;

const Hero = styled(Img)`
  background-color: transparent;
  grid-area: 1 / 2 / 3 / 3;
  border-radius: 5px;
  height: 400px;

  picture {
    object-fit: cover;
  }
  @media (max-width: 768px) {
    grid-area: 1 / 1 / 2 / 3;
    border-radius: 0;
  }
`;

const HHH = styled.div`
  color: white;
  grid-area: 3 / 2 / 4 / 3;

  @media (max-width: 768px) {
    grid-area: 2 / 1 / 4 / 3;
  }
`;

const SubtitleContent = styled.div`
  margin-top: 100px;
  margin-bottom: 200px;
  max-width: 730px;

  @media (max-width: 768px) {
    margin: 60px 16px;
  }
`;

const Label = styled.h3`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
`;

const StyledLink = styled.a`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e43f5a;
`;

const MDXContainer = styled.div`
  width: 100%;
`;

const shortcodes = { Link, Paragraph, Block, Title }; // Provide common components here

export default function PageTemplate({ data: { mdx } }) {
  const { getFluidImageName } = useImage();

  return (
    <Layout isHeroAvailable={false}>
      <EmptySpace desktop={{ margin: 170 }} mobile={{ margin: 180 }} />

      <MDXContainer>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </MDXContainer>

      <EmptySpace desktop={{ margin: 60 }} mobile={{ margin: 60 }} />

      <FullBleed>
        <HeroSection>
          <BG></BG>
          <HHH>
            <SubtitleContent>
              <Label>{mdx.frontmatter.linkLabel}</Label>
              <StyledLink href={mdx.frontmatter.href}>
                {mdx.frontmatter.linkToDisplay}
              </StyledLink>
            </SubtitleContent>
          </HHH>
          <Hero
            fluid={getFluidImageName(
              mdx.frontmatter.heroImage || "class-with-students"
            )}
            alt="Estudantes na sala de aula"
          />
        </HeroSection>
      </FullBleed>

      <EmptySpace desktop={{ margin: 160 }} mobile={{ margin: 110 }} />

      <ProjectsCarousel
        excludeIds={[mdx.id]}
        title="Mais projetos. Mais ideias. Mais ações."
      />

      <EmptySpace desktop={{ margin: 140 }} mobile={{ margin: 110 }} />

      <FullBleed>
        <Newsletter />
      </FullBleed>
      <Seo
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
        image={getFluidImageName(
          mdx.frontmatter.heroImage || "class-with-students"
        )}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        description
        href
        linkLabel
        linkToDisplay
        heroImage
      }
    }
  }
`;
